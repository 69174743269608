import Cookies from 'js-cookie'

const TokenKey = 'Authorization'
const ShiroKey = 'Auth-Session';

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getShiroId() {
  return sessionStorage.getItem(ShiroKey)
}

export function setShiroId(key) {
  return sessionStorage.setItem(ShiroKey, key)
}

export function removeShiroId() {
  return sessionStorage.removeItem(ShiroKey)
}
