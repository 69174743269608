import auth from '@/plugins/auth'
import router, { constantRoutes, dynamicRoutes } from '@/router'
import { getRouters } from '@/api/menu'
import Layout from '@/layout/index'
import ParentView from '@/components/ParentView'
import InnerLink from '@/layout/components/InnerLink'

// 匹配views里面所有的.vue文件
const modules = import.meta.glob('./../../views/**/*.vue')

const usePermissionStore = defineStore(
  'permission',
  {
    state: () => ({
      routes: [],
      addRoutes: [],      // 后台返回的路由
      defaultRoutes: [],  // 静态 + 后台'重写'path的路由
      topbarRouters: [],  // 静态 + 后台未重写path的路由
      sidebarRouters: []  // 静态 + 后台未重写path的路由
    }),
    actions: {
      setRoutes(routes) {
        this.addRoutes = routes                               // 范围：           后台的'重写'path的路由
        this.routes = constantRoutes.concat(routes)           // 范围：静态 +      后台的'重写'path的路由
      },
      setDefaultRoutes(routes) {
        this.defaultRoutes = constantRoutes.concat(routes)    // 范围：静态 +      后台的未重写path的路由
      },
      setTopbarRoutes(routes) {
        this.topbarRouters = routes                           // 范围：静态 +      后台的未重写path的路由
      },
      setSidebarRouters(routes) {
        this.sidebarRouters = routes                          // 范围：静态 +      后台的未重写path的路由
      },
      generateRoutes(roles) {
        return new Promise(resolve => {
          // 向后端请求路由数据
          getRouters().then(res => {
            const sdata =       JSON.parse(JSON.stringify(res.data))
            const rdata =       JSON.parse(JSON.stringify(res.data))
            const defaultData = JSON.parse(JSON.stringify(res.data))

            const sidebarRoutes = filterAsyncRouter(sdata,        false)
            const rewriteRoutes = filterAsyncRouter(rdata,        true)
            const defaultRoutes = filterAsyncRouter(defaultData,  false)

            // 前台硬编码的动态路由表，确认是否具有权限，直接拼到路由上（放在导航守卫上更好）
            const asyncRoutes = filterDynamicRoutes(dynamicRoutes)
            asyncRoutes.forEach(route => { router.addRoute(route) })

            this.setRoutes(rewriteRoutes)
            this.setSidebarRouters(constantRoutes.concat(sidebarRoutes))
            this.setDefaultRoutes(sidebarRoutes)
            this.setTopbarRoutes(constantRoutes.concat(defaultRoutes))

            resolve(rewriteRoutes)
          })
        })
      }
    }
  })

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, type = false) {
  return asyncRouterMap.filter(route => {
    // console.log('filterAsyncRouter', asyncRouterMap, type);
    if (type && route.children) {
      // 构建child路由的path
      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'ParentView') {
        route.component = ParentView
      } else if (route.component === 'InnerLink') {
        route.component = InnerLink
      } else {
        // console.log('filterAsyncRouter:others:', route.component)
        route.component = loadView(route.component)
      }
    }
    if (route.children != null && route.children && route.children.length) {
      // 加载字路由VUE实例
      route.children = filterAsyncRouter(route.children, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

// 封装children（主要是构建path）。第一个参数是child列表，第二个参数代表这些child的parent
// （话说，第一个参数命名是list好不好）
function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        // console.warn('这里不会走到吧？？', childrenMap);
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          } else {
            children.push(c)
          }
        })
        return
      } else {
        console.warn('else parent: do nothing')
      }
    }
    // console.log('filterChildren', lastRouter);
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
      if (el.children && el.children.length) {
        children = children.concat(filterChildren(el.children, el))
        return
      } else {
        console.warn('else no children: do nothing')
      }
    }
    children = children.concat(el)
  })
  return children
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = []
  routes.forEach(route => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route)
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route)
      }
    }
  })
  return res
}

// 只有后台返回的路由表才需要调用
export const loadView = (view) => {
  let res;
  for (const path in modules) {
    const dir = path.split('views/')[1].split('.vue')[0];
    if (dir === view) {
      res = () => modules[path]();
    }
  }
  return res;
}

export default usePermissionStore
